import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import Button from '../../audi-ui-components/Button';
import Thumbnail from '../../components/Thumbnail';
import {
  PRIZE_DRAW_ACTIVE,
  PRIZE_DRAW_CLOSED,
  PRIZE_DRAW_BROKEN,
  PRIZE_DRAW_DRAWN,
  PRIZE_DRAW_SOLD_OUT,
  PRIZE_DRAW_NO_RAFFLE
} from '../../constants';

const PrizeDetails = ({ status, prizes }) => {
  
  const [expanded, setExpanded] = useState(false);
  const [expanding, setExpanding] = useState(false);
  
  const prizesTop = useRef(null);
  
  const onExpandClick = () => {
    let rect = prizesTop.current ? prizesTop.current.getBoundingClientRect() : {};
    let top = rect.top + window.scrollY;
    window.scrollTo({top: top - 119, behaviour: 'smooth'});
    if (expanded) {
      setExpanded(false);
      setExpanding(true);
      setTimeout(() => { setExpanding(false); }, 200);
    } else {
      setExpanding(true);
      setTimeout(() => { setExpanded(!expanded); setExpanding(false); }, 500);
    }
  }
  
  const entryClosed = status === PRIZE_DRAW_CLOSED || status === PRIZE_DRAW_SOLD_OUT;
  const isDrawn = status === PRIZE_DRAW_DRAWN;
  return (
    <div className="prize-details">
      <h3 className="aui-headline-4 mb-2 text-center">Prize details</h3>
      {entryClosed && (
        <div className="page-intro mt-0">
          <p>
            Thank you for everyone who entered the Audi Foundation prize draws and provided vital funding for community projects across Australia.
            <br />Good luck!
          </p>
        </div>
      )}
      
      {prizes && <div className="">
        
        {prizes.length === 1 && <div className="row">
          <div className="col-small-3">&nbsp;</div>
          <div className="prize-major col-small-6">
              <Thumbnail title={prizes[0].title} thumbnail={prizes[0].prizeImageUrl} description={prizes[0].description} />
          </div>
        </div>}
        
        {prizes.length > 1 && <div className="row">
          <div className="prize-major col-small-6">
            <Thumbnail title={prizes[0].title} thumbnail={prizes[0].prizeImageUrl} description={prizes[0].description} />
          </div>
          <div className="prize-major col-small-6">
            <Thumbnail title={prizes[1].title} thumbnail={prizes[1].prizeImageUrl} description={prizes[1].description} />
          </div>
        </div>}
        
        <div ref={prizesTop}>&nbsp;</div>
        {prizes.length > 2 && <div className={classnames("prizes-secondary", {"expanded": (expanded || entryClosed || isDrawn), "expanding": expanding})}>
          <div className="row">
            {prizes.slice(2).map((prize,i) => (
              <div className="col-small-4" key={`prize${i}`}>
                <Thumbnail title={prize.title} thumbnail={prize.prizeImageUrl} description={prize.description} />
              </div>
            ))}
          </div>
          {!(isDrawn || entryClosed) && <div className="text-center my-2">
            <Button variant="secondary" onClick={onExpandClick}>{expanded ? 'Hide all prizes' : 'View all prizes'}</Button>
          </div>}
        </div>}
        
      </div>}
      
    </div>
  );
}

export default PrizeDetails;
