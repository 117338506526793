import { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import _debounce from 'lodash/debounce';

const NavBar = ({ actions = [], onActionClick = ()=>{}, activeIndex, navType = "bar", small, theme, className = "", style = {} }) => {

  const SCROLL_THRESHOLD = 20; // in px; How far to scroll, before paddles are visible.
  const SCROLL_PADDING = 10; // in px;
  const SCROLL_EASING = 0.2;

  const [leftPaddle, setLeftPaddle] = useState(false);
  const [rightPaddle, setRightPaddle] = useState(false);
  const itemsRef = useRef([]);
  const containerRef = useRef(null);
  const panelRef = useRef(null);
  const indicatorRef = useRef(null);
  const scrollStart = useRef(0);
  const scrollDelta = useRef(0);
  const scrollRatio = useRef(0);

  const onScroll = (dir) => {
    let containerBounds = containerRef.current.getBoundingClientRect();
    let elementBounds;
    let left = 0;
    let right = panelRef.current.offsetWidth;
    let scroll = 0;
    if (dir < 0) {
      // Scroll left
      for (let i = itemsRef.current.length - 1; i > 0; i--) {
        let element = itemsRef.current[i];
        elementBounds = element.getBoundingClientRect();
        let elementLeft = elementBounds.left - containerBounds.left;
        if (elementLeft < left) {
          scroll = Math.ceil(elementLeft + panelRef.current.scrollLeft - SCROLL_PADDING);
          break;
        }
      }
    } else {
      // Scroll right
      for (let i = 0; i < itemsRef.current.length; i++) {
        let element = itemsRef.current[i];
        elementBounds = element.getBoundingClientRect();
        let elementRight = elementBounds.left - containerBounds.left + element.offsetWidth;
        if (elementRight > right) {
          scroll = Math.ceil(elementRight + panelRef.current.scrollLeft + SCROLL_PADDING - right);
          break;
        }
      }
    }
    animateScroll(scroll);
  }

  const animateScroll = (scroll) => {
    scrollStart.current = panelRef.current.scrollLeft;
    scrollDelta.current = scroll - scrollStart.current;
    scrollRatio.current = 0;
    window.requestAnimationFrame(() => animateScrollTick());
  }

  const animateScrollTick = () => {
    let ratio = 1 - scrollRatio.current;
    ratio *= SCROLL_EASING;
    scrollRatio.current += ratio;
    if (ratio < 0.001) {
      scrollRatio.current = 1;
    }
    panelRef.current.scrollLeft = scrollStart.current + scrollDelta.current * scrollRatio.current;
    if (scrollRatio.current !== 1) {
      window.requestAnimationFrame(() => animateScrollTick());
    } else {
      updatePaddleVisibility();
    }
  }

  const updatePaddleVisibility = () => {
    if (containerRef.current !== null && panelRef.current !== null) {
      if (panelRef.current.scrollLeft - SCROLL_THRESHOLD > 0) {
        setLeftPaddle(true);
      } else {
        setLeftPaddle(false);
      }
      if (panelRef.current.scrollLeft + containerRef.current.offsetWidth + SCROLL_THRESHOLD < panelRef.current.scrollWidth) {
        setRightPaddle(true);
      } else {
        setRightPaddle(false);
      }
    }
  }

  const updateIndicatorPosition = () => {
    // console.log("updateIndicatorPosition", activeIndex, test);
    if (typeof activeIndex === "undefined" || activeIndex === null) {
      if (indicatorRef.current !== null) {
          indicatorRef.current.style.width = '0';
      }
    } else {
      if (containerRef.current !== null) {
        let activeTarget = itemsRef.current[`${activeIndex}`];
        let containerBounds = containerRef.current.getBoundingClientRect();
        let rectTarget = activeTarget.getBoundingClientRect();
        let indicatorLeft = rectTarget.left - containerBounds.left + panelRef.current.scrollLeft;
        let indicatorWidth = activeTarget.offsetWidth;
        indicatorRef.current.style.left = `${indicatorLeft}px`;
        indicatorRef.current.style.width = `${indicatorWidth}px`;
      }
    }
  }

  const update = () => {
    // console.log("update");
    updateIndicatorPosition("update");
    updatePaddleVisibility();
  }

  useEffect(() => {
    updatePaddleVisibility();
    const debouncedUpdate = _debounce(update, 500);
    window.addEventListener("resize", debouncedUpdate, false);
    return () => {
      window.removeEventListener("resize", debouncedUpdate, false);
    }
  }, [actions.length, activeIndex]);

  useEffect(() => {
    const observer = new ResizeObserver(updateIndicatorPosition);
    if (itemsRef.current?.length > 0) {
      observer.observe(itemsRef.current[0]);
    }
    return () => {
      observer.disconnect();
    }
  }, [itemsRef.current?.length, activeIndex]);

  return(
    <nav ref={containerRef} className={classnames(`${className}`, "aui-nav", `aui-nav--${navType}`, "is-animated", {"aui-nav--small": small, [`aui-theme-${theme}`]: theme})} style={style}>
      <div ref={panelRef} className="aui-nav__panel">
        <ul className="aui-nav__items">
          {actions.map((action, index) => {
            if (typeof action.enabled !== "undefined" && action.enabled === false) { return null; }
            let _active = index === activeIndex;
            let Component = action.to ? Link : action.href ? "a" : "button";
            let link = {};
            if (action.to) { link.to = action.to; }
            if (action.href) { link.href = action.href; }
            if (!action.to && !action.href) { link.type = "button"; }
            return (
              <li key={`ni_${index}`} className={classnames("aui-nav__item", action.className)}>
                <Component ref={(el) => itemsRef.current[index] = el}
                  {...link}
                  className={classnames("aui-nav__action", action.className, {"is-active": _active, "is-disabled": action.disabled})}
                  onClick={(e) => {if (!action.disabled) { onActionClick(index, e); }}}
                  disabled={action.disabled}
                >
                  {action.text || action.label}
                </Component>
              </li>
            );
          })}
        </ul>
        <span ref={indicatorRef} className="aui-nav__indicator"></span>
      </div>
      <div className="aui-nav__paddles">
        {leftPaddle && <button type="button" className="aui-nav__paddle-left" onClick={(e) => { onScroll(-1); e.preventDefault(); }}></button>}
        {rightPaddle && <button type="button" className="aui-nav__paddle-right" onClick={(e) => { onScroll(1); e.preventDefault(); }}></button>}
      </div>
    </nav>
  );
}

// NavBar.propTypes = {
//   actions: PropTypes.arrayOf(PropTypes.shape({
//     text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
//     to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
//     href: PropTypes.string,
//     target: PropTypes.string,
//     rel: PropTypes.string,
//     className: PropTypes.string
//   })),
//   activeIndex: PropTypes.number,
//   onActionClick: PropTypes.func,
//   small: PropTypes.bool,
//   navType: PropTypes.oneOf(['bar', 'tab']),
//   className: PropTypes.string,
//   style: PropTypes.object,
//   theme: PropTypes.oneOf(['light', 'black', 'warmsilver', 'silver', 'red']) // see styles/components/_nav-themes.scss
// };

export default NavBar;
